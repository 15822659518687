export class TranslationKeys {

  private _moduleKey: string;
  constructor(moduleKey: string) {
    this._moduleKey = moduleKey;
  }

  public getKey = (key: string) => `${this._moduleKey}.${key}`;
  public getGeneralKey = (key: string) => `general.${key}`;
  public getMessage = (key: string) => `messages.${key}`;
}


