import { ChangeDetectorRef, Component, inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";
import { ConfigurationsService, LookupsService } from "../../openapi";
import { FormControl, FormGroup } from "@angular/forms";
import { PaginationResult } from "./models/pagination-result";
import { TableLazyLoadEvent } from "primeng/table";
import { Constants } from "../../../../shared/src/lib/constants";
import { getDirection } from "../../../../shared/src/lib/helpers/helpers";
import { ConfigService } from "../../../../shared/src/lib/services/config.service";
import { CachedLookupServiceService } from "./services/cached-lookup-service.service";
import { AuthService } from "../pages/auth/auth.service";
import { SharedEvents } from "../../../../shared/src/lib/events";

export abstract class BaseComponent {
  protected get dir() { return getDirection() };
  protected get isAr() { return localStorage.getItem('lang') == 'ar' };
  protected get htmlDir() {
    const htmlElement = document.querySelector('html') as HTMLElement;
    if (!htmlElement) {
      return null;
    }

    return htmlElement.getAttribute('dir');
  }

  protected readonly toastrService = inject(ToastrService);
  protected readonly translationService = inject(TranslateService);
  protected readonly confirmationService = inject(ConfirmationService);
  protected readonly router = inject(Router);
  protected readonly activeRoute = inject(ActivatedRoute);
  protected readonly lookupsService = inject(LookupsService);
  protected readonly cachedLookupsService = inject(CachedLookupServiceService);
  protected readonly configService = inject(ConfigService);
  protected readonly authService = inject(AuthService);
  protected readonly _configurationService = inject(ConfigurationsService)

  protected isMobileScreen: boolean = false;


  protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly constants = Constants;

  protected submitBusy = false;

  constructor() {

    SharedEvents.ScreenIsMobileEvent
      .listener.subscribe(isMobile => this.isMobileScreen = isMobile);
  }

  // Method to log form errors
  logFormErrors(formGroup: FormGroup, parentControlName: string = '') {
    console.log('Form Errors:', formGroup.errors);
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        // If control is a sub form group, recursively call logFormErrors()
        this.logFormErrors(control, key);
      } else {
        const controlErrors = control?.errors;
        if (controlErrors != null) {
          console.debug(`Control '${parentControlName ? parentControlName + '.' : ''}${key}' Errors:`, controlErrors);
        }
      }
    });
  }

  protected scrollUp() {
    window.scrollTo(0, 0);
  }

  protected scrollDown() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  protected handlePagination(paginationResult: PaginationResult, tableLazyLoadEvent: TableLazyLoadEvent) {
    paginationResult.pageNumber = tableLazyLoadEvent.first! / tableLazyLoadEvent.rows! + 1;
    paginationResult.pageSize = tableLazyLoadEvent.rows ?? 10;
    return paginationResult;
  }

  toBase64(value: string) {
    return btoa(value);
  }
  fromBase64(value: string) {
    return atob(value);
  }

  protected back() {
    history.back();
  }

  protected removeValidation<TValue>(formControl: FormControl<TValue>) {
    formControl.setValidators([]);
    formControl.updateValueAndValidity();
  }
}
